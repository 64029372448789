import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { get } from 'lodash'

import CustomerNavigation from '../CustomerNavigation/CustomerNavigation'
import CustomerAreaTitle from '../Display/CustomerAreaTitle'
import LetterBoxDocument from './LetterBoxDocument'
import LetterBoxPagination from './LetterBoxPagination'
import CustomDateRangePicker from '../../ui/CustomDateRangePicker'
import ClosableNotice from '@components/ClosableNotice'

import { STRIPE_PUBLIC_KEY } from 'Config/constants'

import './letter-box.sass'

import { notify } from '../../../actions/notifications'

class LetterBox extends Component {
  constructor(props) {
    super(props)
    const mq = window.matchMedia('(max-width: 1110px)')

    this.state = {
      documents: [],
      totalPages: 1,
      currentPage: 0,
      startDate: moment().subtract(3, 'months'),
      endDate: moment(),
      isMobile: mq.matches, // now not only mobile, also small desktops < 1110px need abbreviated column titles
    }
  }

  componentDidMount() {
    this.fetchDocuments()
  }

  fetchDocuments() {
    const { currentPage, startDate, endDate } = this.state
    if (!(startDate && endDate)) return

    fetch(
      `/api/user/documents?page=${currentPage}&start=${startDate.unix()}&end=${endDate.unix()}`,
      {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      },
    )
      .then(res => res.json())
      .then(({ documents, pages, colis }) => {
        this.setState({
          documents,
          totalPages: pages,
          colis,
        })
      })
      .catch(() => this.props.dispatch(notify('error', 'Une erreur est survenue')))
  }

  updateCurrentPage(newPage) {
    this.setState({ currentPage: newPage }, this.fetchDocuments.bind(this))
  }

  handleDateChange(startDate, endDate) {
    this.setState({ startDate, endDate }, this.fetchDocuments.bind(this))
  }

  renderDocuments() {
    const { currentUser } = this.props
    const { isMobile } = this.state
    if (!this.state.documents || !currentUser.id) return null
    const expFrequency = currentUser?.contracts[0]?.expedition_frequency

    const header = (
      <div className="letter-box-document-container">
        <div className="letter-box-document-main header">
          <div className="letter-box-document-block">Nom</div>
          <div className="letter-box-date">{isMobile ? 'Scan' : 'Scan'}</div>
          <div className="letter-box-date">{isMobile ? 'Réexp.' : 'Réexpédition'}</div>
        </div>
      </div>
    )

    const docs = this.state.documents.map(doc => (
      <LetterBoxDocument
        key={doc.id}
        id={doc.id}
        displayUrl={doc.displayUrl}
        name={doc.name}
        xelians_id={doc.xelians_id}
        customName={doc.custom_name}
        createdAt={doc.created_at}
        renvoiRequestDate={doc.renvoi_request_date}
        refetchDocs={this.fetchDocuments.bind(this)}
        isMobile={isMobile}
        expFrequency={expFrequency}
      />
    ))

    return (
      <>
        {header}
        {docs}
      </>
    )
  }

  render() {
    const { totalPages, currentPage, startDate, endDate, colis } = this.state
    const { currentUser } = this.props

    if (!currentUser.company || !currentUser.contracts[0]) return null

    return (
      <CustomerNavigation currentPage="lettres">
        <div className="customer-area-content-mobile-container LetterBoxPage">
          <ClosableNotice user={currentUser} />
          <div className="letter-box-title">
            <CustomerAreaTitle label="Boite aux lettres" icon="ic-boite-aux-lettres" />
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)}
            />
          </div>
          <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
            <Elements locale="fr">{this.renderDocuments()}</Elements>
          </StripeProvider>
          <LetterBoxPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onSelectPage={page => this.updateCurrentPage(page)}
          />
          <div className="colis-block">
            <CustomerAreaTitle label={`Colis : ${colis ? colis.length : 0}`} />
          </div>
        </div>
      </CustomerNavigation>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
})

export default connect(mapStateToProps)(LetterBox)
