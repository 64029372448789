import React, { useState } from 'react'
import moment from 'moment'

const creationText = <span>L’adresse de domiciliation que vous avez choisie sera l’adresse officielle de votre entreprise. Elle apparaîtra sur votre Kbis (ou avis INSEE), sur Internet, et vous y recevrez votre courrier. Vous pourrez également l’utiliser sur vos supports de communication (site web, factures, cartes de visite, etc.).<br/><br/>
Selon l’activité de votre entreprise individuelle (ou micro-entreprise), vous serez rattaché au régime fiscal BIC ou BNC.<br/>
Si vous êtes exclusivement au régime BNC, votre adresse fiscale sera votre adresse personnelle. Cela signifie que vos démarches fiscales dépendront du Service des Impôts des Entreprises (SIE) le plus proche de votre domicile personnel.<br/><br/>
Ainsi, au moment de la création de votre entreprise, vous recevrez peut-être un courrier du SIE indiquant que l’adresse de domiciliation fiscale est rejetée. Pas d’inquiétude&nbsp;: cela ne remet pas en cause l’adresse officielle de votre siège social.<br/><br/>
Si vous souhaitez bénéficier d’une domiciliation fiscale à l’adresse de domiciliation, vous pouvez ajouter une activité secondaire commerciale (BIC) en complément de votre activité principale (BNC).<br/><br/>
Nous restons à votre disposition si vous avez la moindre question.
</span>

const transfertText = <span>L’adresse de domiciliation que vous avez choisie sera l’adresse officielle de votre entreprise. Elle apparaîtra sur votre Kbis (ou avis INSEE), sur Internet, et vous y recevrez votre courrier. Vous pourrez également l’utiliser sur vos supports de communication (site web, factures, cartes de visite, etc.).<br/><br/>
D’après votre SIREN et votre code APE, votre Entreprise Individuelle (ou micro-entreprise) dépend du régime fiscal BNC. Cela signifie que votre adresse fiscale est à votre adresse personnelle. C’est donc le Service des Impôts des Entreprises (SIE) le plus proche de votre domicile personnel qui gérera la fiscalité de votre entreprise.<br/><br/>
Ainsi, au moment du transfert du siège de votre entreprise, vous recevrez peut-être un courrier du SIE indiquant que l’adresse de domiciliation fiscale est rejetée. Pas d’inquiétude&nbsp;: cela ne remet pas en cause l’adresse officielle de votre siège social.<br/><br/>
Nous restons à votre disposition si vous avez la moindre question.
</span>

interface Props {
  user: {
    juridic_form: string
    is_transfer: string
    siren: string | null
    created_at: Date
    has_regime_commercial: boolean
  }
}

const ClosableNotice = ({ user } : Props) => {
  const { is_transfer, created_at, juridic_form, siren, has_regime_commercial } = user
  const [isOpen, setOpen] = useState(true)

  if (!isOpen) return null
  if (moment(created_at).isBefore(moment('23/03/2025', 'DD/MM/YYYY'))) return null // only recent users
  if (!['Micro-entreprise', 'EI'].includes(juridic_form)) return null
  if (siren && has_regime_commercial) return null

  const text = is_transfer === 'true' ? transfertText : creationText

  return (
    <div className="bg-new-verylight-gray border-verylight-gray border-1 px-5 py-5 rounded relative mb-8">
      <img onClick={() => setOpen(false)} src="/assets/icons/icon-cross.svg" className="cursor-pointer absolute top-1 right-1" alt="fermer" />
      <div className="font-semibold">Votre domiciliation fiscale</div>
      <br/>
      <div className="text-justify">{text}</div>
    </div>
  )
}

export default ClosableNotice